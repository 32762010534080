import { ErrorMessage } from '@hookform/error-message'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { dt } from 'Root/Shared/utils/dynamicRS'

import { FormFieldInputType } from '../builders/FormBuilder'
import { WidgetProperitesPart } from '../builders/WidgetBuilder'
import FormErrorHelper from './FormErrorHelper'
import { getClass, getRequiredField } from './formFieldHelper'

const FormFieldInputWidget: React.FC<{
  props: FormFieldInputType
  widgetProperties?: WidgetProperitesPart
}> = ({ props, widgetProperties }) => {
  const stepable = [`number`, `date`, `datetime-local`, `time`]
  const multiple = [`email`, `file`]

  const {
    control,
    formState: { errors },
  } = useFormContext()

  const formElement = props?.puxFormElement
  const formValidation = props?.puxFormElementValidation
  const formOptions = props?.puxFormElementOptions

  const getStepValue = (): number | undefined => {
    if (
      stepable.includes(props?.inputType) &&
      formOptions?.formElementOptionsStep?.toString() !== `1.0`
    ) {
      return formOptions?.formElementOptionsStep
    }
    return undefined
  }

  const isMultipleOptionsEnabled = (): boolean => {
    return (
      multiple.includes(props?.inputType) &&
      formOptions?.formElementOptionsMultiple
    )
  }

  const pattern = formValidation.formElementValidationPattern
    ? new RegExp(formValidation.formElementValidationPattern, ``)
    : new RegExp(`[sS]*`, ``)

  return (
    <div
      className={`FormGroup ${
        formOptions?.formElementOptionsCssClass ?? ``
      } ${getClass(props.metadata?.size, widgetProperties)} FormGroup--${
        props.inputType
      }Input`}
    >
      <label
        htmlFor={formElement?.formElementID}
        id={formElement?.formElementName}
      >
        {dt(`${formElement?.formElementLabel}`)}
        {getRequiredField(
          props?.puxFormElementValidation?.formElementValidationRequired
        )}
      </label>
      <Controller
        name={formElement?.formElementID}
        control={control}
        rules={{
          required: formValidation.formElementValidationRequired
            ? dt(`PuxForm.Error.Required`)
            : false,
          min: {
            value: formValidation.formElementValidationMin ?? undefined,
            message: dt(`PuxForm.Error.Min.${props?.inputType ?? ``}`),
          },
          max: {
            value: formValidation.formElementValidationMax ?? undefined,
            message: dt(`PuxForm.Error.Max.${props?.inputType ?? ``}`),
          },
          minLength: {
            value: formValidation.formElementValidationMinLength ?? undefined,
            message: dt(`PuxForm.Error.Minlength.${props?.inputType ?? ``}`),
          },
          maxLength: {
            value: formValidation.formElementValidationMaxLength ?? undefined,
            message: dt(`PuxForm.Error.Maxlength.${props?.inputType ?? ``}`),
          },
          pattern: {
            value: pattern,
            message: dt(`PuxForm.Error.Pattern.${props?.inputType ?? ``}`),
          },
        }}
        render={({ field }) => (
          <input
            {...field}
            type={props?.inputType}
            placeholder={formElement?.formElementPlaceholder}
            autoFocus={formOptions?.formElementOptionsAutofocus}
            readOnly={formOptions?.formElementOptionsReadOnly}
            disabled={formOptions?.formElementOptionsDisabled}
            multiple={isMultipleOptionsEnabled()}
            autoComplete={
              formOptions?.formElementOptionsAutocomplete ? `on` : `off`
            }
            step={getStepValue()}
          />
        )}
      />
      <ErrorMessage
        render={(data) => <FormErrorHelper {...data} />}
        errors={errors}
        name={formElement?.formElementID ?? ``}
      />
    </div>
  )
}

export default FormFieldInputWidget
