import React, { FunctionComponent } from 'react'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'

import PuxNumbersContainer, {
  PuxNumbersContainerType,
} from '../PuxNumber/PuxNumbersContainer'

export interface WidgetPuxNumbersContainerType
  extends WidgetBase,
  PuxNumbersContainerType {
  contentType: 'PuxNumbersContainer'
}

const WidgetPuxNumbersContainer: FunctionComponent<WidgetPuxNumbersContainerType> = (
  props
) => (
  <WidgetContainer
    {...props.puxWidgetProperties}
    {...props.puxWidgetAnimation}
    {...props.metadata}
  >
    <PuxNumbersContainer
      displayText={props.displayText}
      numbersContainerShowProgress={props.numbersContainerShowProgress}
      numbersContainerLayout={props.numbersContainerLayout}
      numberRadius={props.numberRadius}
      numberStroke={props.numberStroke}
      puxNumbersContainerItems={props.puxNumbersContainerItems}
    />
  </WidgetContainer>
)

export default WidgetPuxNumbersContainer
