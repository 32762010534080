import './EasySoftwareEditorBar.scss'

import React from 'react'
import { toast } from 'react-toastify';

const EasySoftwareEditorBar = ({ isAuthenticated = true, wysiwygOptions = undefined, isPreview = false }) => {

  if (wysiwygOptions) {
    return (
      // <div className='EditorBar EditorBar--wysiwyg' style={{ transform: `translate(0, ${wysiwygOptions.active ? '0' : '-100%'})` }}>
      <div className='EditorBar EditorBar--wysiwyg' style={{ transform: `translate(0, 0)` }}>
        <div className='EditorBar-container'>
          <WysiwygEditorBar wysiwygOptions={wysiwygOptions} />
        </div>
      </div >
    )
  }

  return (
    <div className='EditorBar'>
      <div className='EditorBar-container'>
        <EditorBar isAuthenticated={isAuthenticated} isPreview={isPreview} />
      </div>
    </div >
  )
}

const EditorBar = ({ isAuthenticated = true, isPreview }) => {
  function savePage() {
    const saveButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll(`.WysiwygEditor-button.WysiwygEditor-button--changed`)

    if (saveButtons.length === 0) {
      toast.info(`Nothing to save.`)
      return
    }

    saveButtons.forEach((saveButton: HTMLButtonElement) => {
      saveButton.click()
    })
  }

  return (
    <>
      <div>{isPreview ? "Preview" : "Editor"} mode</div>
      {isAuthenticated ? (
        null
      ) : (
        <a
          className='Button Button--primary Button--green Button--medium'
          href={`${process.env.GATSBY_ORCHARD_API_URL}/Login`}
          target='_blank'>
          Login to {isPreview ? "view" : "edit"} 🚀
        </a>
      )}
    </>
  )
}

const WysiwygEditorBar = ({ wysiwygOptions }) => {
  return (
    <>
      <div>Wysiwyg mode</div>
      <div id={wysiwygOptions.id}>
        <span className="ql-formats">
          <span className="ql-header ql-picker">
            <select className="ql-header">
              <option value="1"></option>
              <option value="2"></option>
              <option value="3"></option>
              <option value="4"></option>
              <option value="5"></option>
              <option value="6"></option>
              <option selected></option>
            </select>
          </span>
        </span>
        <span className="ql-formats">
          <select className="ql-align">
            <option selected></option>
            <option value="center"></option>
            <option value="right"></option>
            <option value="justify"></option>
          </select>
        </span>
        <span className="ql-formats">
          <button className="ql-bold"></button>
          <button className="ql-italic"></button>
          <button className="ql-underline"></button>
        </span>
        <span className="ql-formats">
          <button type="button" className="ql-code-block"></button>
        </span>
        <span className="ql-formats">
          <button type="button" className="ql-list" value="ordered"></button>
          <button type="button" className="ql-list" value="bullet"></button>
        </span>
      </div>
      <button
        className='Button Button--primary Button--orange Button--medium'
        onClick={() => wysiwygOptions.closeHandler()}
      >
        Close editor
      </button>
      <button
        className='Button Button--primary Button--green Button--medium'
        onClick={() => wysiwygOptions.saveHandler()}
        ref={wysiwygOptions?.saveButtonRef}
      >
        Save editor 💾
      </button>
    </>
  )
}

export default EasySoftwareEditorBar
