export const getSectionQuery = (section: string): string => {
    return `${section} {
        widgets {
            ... on Orchard_PuxSection {
                contentItemId
                contentType
                sectionUseContainer
                sectionPerex {
                    html
                }
                sectionHorizontalAlign
                sectionVerticalAlign
                sectionIsModal
                sectionModalId
                sectionButton {
                    buttonColor
                    buttonNewWindow
                    buttonType
                    buttonSize
                    buttonOpenModalId
                    buttonLink {
                        url
                        internal
                        text
                    }
                }
                puxBackgroundDefinition {
                    backgroundDefinitionPartColor
                }
                puxWidgetProperties {
                    widgetPropertiesPartID
                    widgetPropertiesPartMarginTop
                    widgetPropertiesPartPaddingTop
                    widgetPropertiesPartMarginBottom
                    widgetPropertiesPartPaddingBottom
                }
                puxWidgetAnimation {
                    widgetAnimationPartType
                    widgetAnimationPartIsEnabled
                    widgetAnimationPartFraction
                    widgetAnimationPartDuration
                    widgetAnimationPartDelay
                }
                flow {
                    widgets {
                        %baseSectionQueryContentType%
                        %widgetFragmentsPlaceholder%
                    }
                }
            }
        }
    }`
}

export const getSectionsQueries = (sections) => {
    if (sections === undefined) return ``
    return sections.map(getSectionQuery).join(`\n`)
}