import './LandingPage.scss'

//@ts-ignore
import { HeaderData } from '@componentsfld/core/HeaderNav'
//@ts-ignore
import Layout from '@componentsfld/core/Layout'
import { HeadProps } from 'gatsby'
import React, { FunctionComponent } from 'react'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import {
  menuItemsMapper,
} from 'Shared/utils/menuItemsMapper'
import PuxMetaTagsGatsby from 'Root/Shared/utils/PuxMetaTagsGatsby'
import { IPageContext } from 'Root/Shared/utils/data/buildTypedPage'
import { GenericPageProps, IEasySoftwareLandingPage } from 'Root/Shared/queries/page-queries'

const rootClass = `landing-page`

const LandingPage: FunctionComponent<GenericPageProps<IEasySoftwareLandingPage>> = (props) => {
  const pageData = props.pageContext.pageData
  const widgetZone = props.pageContext.widgets?.landingWidgets
  const headerData = props.pageContext.headerData
  const footerData = props.pageContext.footerData

  const landingHeaderData: HeaderData = {
    menuItems: menuItemsMapper(pageData?.landingPageAnchors?.contentItems),
  }
  const editorPath = `landingWidgets,widgets`

  return (
    <Layout
      localizedPath={pageData.localization?.localizations}
      style='landing'
      cta={pageData?.landingPageButton}
      footerData={footerData}
      headerData={headerData}
      landingPageNav={landingHeaderData}
    >
      <div className={rootClass}>
        <div className={`${rootClass}-widgets`}>
          <WidgetBuilder pageContentItemId={pageData.contentItemId} widgetBuilderData={widgetZone} editorPath={editorPath} />
        </div>
      </div>
    </Layout >
  )
}

export default LandingPage

export function Head(props: HeadProps<never, IPageContext<IEasySoftwareLandingPage>>) {
  const pageData = props.pageContext.pageData
  const metaTags = props.pageContext.metaTags

  if (pageData.easySoftwareOptimizeSettings &&
    pageData.easySoftwareOptimizeSettings.optimizeSettingsAntiFlicker &&
    pageData.easySoftwareOptimizeSettings.optimizeSettingsContainer) {
    return <>
      <style>{`.async-hide {opacity: 0 !important}`}</style>
      <script>
        {`
            (function(a,s,y,n,c,h,i,d,e){s.className += ' ' + y;h.start=1*new Date;
            h.end=i=function(){s.className = s.className.replace(RegExp(' ?' + y), '')};
            (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
            })(window,document.documentElement,'async-hide','dataLayer',4000,
            {'${pageData.easySoftwareOptimizeSettings.optimizeSettingsContainer}':true});`}
      </script>
      <PuxMetaTagsGatsby {...metaTags} localizedPaths={pageData.localization?.localizations} />
    </>
  }

  return <PuxMetaTagsGatsby {...metaTags} localizedPaths={pageData.localization?.localizations} />
}

