import './PricingPage.scss'
//@ts-ignore
import Layout from '@componentsfld/core/Layout'
import { HeadProps } from 'gatsby'
import React, { FunctionComponent } from 'react'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import PuxAnchor from 'Shared/components/PuxAnchor/PuxAnchor'
import { t } from 'ttag'
//@ts-ignore
import background from '../../images/PricingPage.svg'
import AllFeaturesTable from './AllFeaturesTable'
import PlansTable from './PlansTable'
import { PuxAnchorLink } from 'Shared/utils/PuxAnchorLink/PuxAnchorLink'
import PuxMetaTagsGatsby from 'Shared/utils/PuxMetaTagsGatsby'
import { IPageContext } from 'Root/Shared/utils/data/buildTypedPage'
import { GenericPageProps, IEasySoftwarePricingPage } from 'Shared/queries/page-queries'
import EasySoftwareEditorContainer from 'Shared/components/EasySoftwareEditorContainer/EasySoftwareEditorContainer'
import { PuxWysiwygHtmlBodyType } from 'Root/Shared/components/PuxWysiwyg/PuxWysiwyg'

export interface PricingCurrencyType {
  contentType: string
  contentItemId: string
  displayText: string
  pricingCurrencySymbol: string
  pricingCurrencyPriceEssential: string
  pricingCurrencyPriceBusiness: string
  pricingCurrencyPricePlatform: string
  pricingCurrencyDefaultInCountriesList: string[]
}

export interface PricingFeatureType {
  contentType: string
  contentItemId: string
  displayText: string
  pricingFeatureTooltip: string
  pricingFeaturePlanEssential: boolean
  pricingFeaturePlanBusiness: boolean
  pricingFeaturePlanPlatform: boolean
  pricingFeaturePlanEnterprise?: boolean,
  pricingFeaturePlanEssentialInput: PuxWysiwygHtmlBodyType
  pricingFeaturePlanBusinessInput: PuxWysiwygHtmlBodyType
  pricingFeaturePlanPlatformInput: PuxWysiwygHtmlBodyType
  pricingFeaturePlanEnterpriseInput: PuxWysiwygHtmlBodyType
}

export interface PricingFeatureCategoryType {
  displayText: string
  contentType: string
  contentItemId: string
  pricingFeatureCategoryItems: {
    contentItems: [PricingFeatureType]
  }
}

const PricingPage: FunctionComponent<GenericPageProps<IEasySoftwarePricingPage>> = (props) => {
  const pageData = props.pageContext.pageData
  const headerData = props.pageContext.headerData
  const footerData = props.pageContext.footerData
  const currencies =
    pageData.pricingPageCurrencies &&
    pageData.pricingPageCurrencies.contentItems &&
    pageData.pricingPageCurrencies.contentItems.length &&
    pageData.pricingPageCurrencies.contentItems
  const features =
    pageData.pricingPageFeatures &&
    pageData.pricingPageFeatures.contentItems &&
    pageData.pricingPageFeatures.contentItems.length &&
    pageData.pricingPageFeatures.contentItems
  const allFeatures =
    pageData.pricingPageAllFeatures &&
    pageData.pricingPageAllFeatures.contentItems &&
    pageData.pricingPageAllFeatures.contentItems.length &&
    pageData.pricingPageAllFeatures.contentItems

  const midWidgets = props.pageContext.widgets?.midWidgetZone
  const bottomWidgets = props.pageContext.widgets?.bottomWidgetZone

  return (
    <Layout
      localizedPath={pageData.localization?.localizations}
      footerData={footerData}
      headerData={headerData}
    >
      <div className='PricingPage'>
        <div className='PricingPage-header'>
          <img className='PricingPage-background' src={background} />
          <div className='Container'>
            <h1 className='PricingPage-title'>{pageData.displayText}</h1>
            <div className='PricingPage-perex perex'>
              <EasySoftwareEditorContainer pageContentItemId={pageData.contentItemId} wysiwygId={pageData.contentItemId+"-perex"} content={pageData.pricingPagePerex} editorPath={"easySoftwarePricingPage,pricingPagePerex,html"} />
            </div>
          </div>
        </div>
        <div className='Container PricingPage-table'>
          <PlansTable
            currencies={currencies}
            features={features}
            enterpriseContent={pageData.pricingPageEnterpriseContent}
            buyLink={pageData.pricingPageBuyLink}
            locale={props.pageContext.isoCode}
            editorPath={"easySoftwarePricingPage,pricingPageEnterpriseContent,html"}
            pageContentItemId={pageData.contentItemId}
            wysiwygId={pageData.contentItemId+"-content"}
          />
          <div className='PricingPage-comparison'>
            <PuxAnchorLink
              to='#comparison'
              className='Button Button--blue Button--link Button--medium Button--scrollDown'
            >
              <h4>{t`Redmine.Pricing.ComparisonDetail`}</h4>
            </PuxAnchorLink>
          </div>
        </div>

        <div className='Container'>
          <div className='PricingPage-mid-widgets'>
            <WidgetBuilder widgetBuilderData={midWidgets} pageContentItemId={pageData.contentItemId} editorPath={"midWidgetZone,widgets"} />
          </div>

          <PuxAnchor id='comparison'>
            <div className='widget-section section-grey'>
              <div className='PricingPage-features pux-container'>
                <h2 className='PricingPage-features-title'>
                  {t`Redmine.Pricing.CompleteFeatures`}
                </h2>
                <AllFeaturesTable allFeatures={allFeatures} />
              </div>
            </div>
          </PuxAnchor>

          <div className='PricingPage-bottom-widgets'>
            <WidgetBuilder widgetBuilderData={bottomWidgets} pageContentItemId={pageData.contentItemId} editorPath={""} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PricingPage

export function Head(props: HeadProps<never, IPageContext<IEasySoftwarePricingPage>>) {
  const pageData = props.pageContext.pageData
  const metaTags = props.pageContext.metaTags

  return <PuxMetaTagsGatsby {...metaTags} localizedPaths={pageData.localization?.localizations} />
}