import './easySoftwarePage.scss'
//@ts-ignore
import Layout from '@componentsfld/core/Layout'
import React, { FunctionComponent } from 'react'
import { HeadProps } from 'gatsby'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import EasySoftwareBreadcrumbs, {
  breadcrumbsOnTopImage,
} from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import PuxMetaTagsGatsby from '../utils/PuxMetaTagsGatsby'
import { GenericPageProps, IEasySoftwarePage } from '../queries/page-queries'
import { IPageContext } from '../utils/data/buildTypedPage'

const EasySoftwarePage: FunctionComponent<GenericPageProps<IEasySoftwarePage>> = ({
  pageContext,
}) => {
  const pageData = pageContext.pageData
  const breadcrumbsData = pageContext.breadcrumbs
  const headerData = pageContext.headerData
  const footerData = pageContext.footerData
  const editorPath = `widgetZone,widgets`

  return (
    <Layout localizedPath={pageData.localization?.localizations}
      footerData={footerData}
      headerData={headerData}
      customLayoutClass={
        breadcrumbsOnTopImage(pageContext?.widgets?.widgetZone) &&
          breadcrumbsData?.length
          ? `has-topimage`
          : ``
      }
    >
      <div className="EasySoftwarePage">
        <EasySoftwareBreadcrumbs items={breadcrumbsData} />
        <WidgetBuilder widgetBuilderData={pageContext?.widgets?.widgetZone} pageContentItemId={pageData.contentItemId} editorPath={editorPath} />
      </div>
    </Layout>
  )
}

export default EasySoftwarePage

export function Head(props: HeadProps<never, IPageContext<IEasySoftwarePage>>) {
  const pageData = props.pageContext.pageData
  const metaTags = props.pageContext.metaTags

  return <PuxMetaTagsGatsby {...metaTags} localizedPaths={pageData.localization?.localizations} />
}