export const localeConfig: configType = {
  EasyRedmineGatsby: {
    kr: {
      locale: `kr`,
      urlPrefix: `kr`,
      preferedContentLinkCulture: ``,
      isoCode: `ko-KR`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    jp: {
      locale: `jp`,
      urlPrefix: `jp`,
      preferedContentLinkCulture: ``,
      isoCode: `ja-JP`,
      mautic: {
        src: `https://mautic.easyredmine.jp/media/js/mautic-form.js`,
        domain: `https://mautic.easyredmine.jp`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    ru: {
      locale: `ru`,
      urlPrefix: `ru`,
      preferedContentLinkCulture: ``,
      isoCode: `ru-RU`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    br: {
      locale: `br`,
      urlPrefix: `br`,
      preferedContentLinkCulture: ``,
      isoCode: `pt-BR`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    es: {
      locale: `es`,
      urlPrefix: `es`,
      preferedContentLinkCulture: ``,
      isoCode: `es-ES`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    fr: {
      locale: `fr`,
      urlPrefix: `fr`,
      preferedContentLinkCulture: ``,
      isoCode: `fr-FR`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    de: {
      locale: `de`,
      urlPrefix: `de`,
      preferedContentLinkCulture: ``,
      isoCode: `de-DE`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    en: {
      locale: `en`,
      urlPrefix: ``,
      preferedContentLinkCulture: ``,
      isoCode: `en-GB`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
  },
  EasyProjectGatsby: {
    ru: {
      locale: `ru`,
      urlPrefix: `ru`,
      preferedContentLinkCulture: ``,
      isoCode: `ru-RU`,
      mautic: {
        src: `https://mautic.easyproject.com/media/js/mautic-form.js`,
        domain: `https://mautic.easyproject.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-project.puxdesign.net`,
        stage: `http://dev1-ep.easysoftware.client.puxdesign.cz`,
        rdn: `http://rdn-1-ep.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyproject.com`,
      },
      GTM: `GTM-K75449W`,
    },
    de: {
      locale: `de`,
      urlPrefix: `de`,
      preferedContentLinkCulture: ``,
      isoCode: `de-DE`,
      mautic: {
        src: `https://mautic.easyproject.com/media/js/mautic-form.js`,
        domain: `https://mautic.easyproject.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-project.puxdesign.net`,
        stage: `http://dev1-ep.easysoftware.client.puxdesign.cz`,
        rdn: `http://rdn-1-ep.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyproject.com`,
      },
      GTM: `GTM-K75449W`,
    },
    fr: {
      locale: `fr`,
      urlPrefix: `fr`,
      preferedContentLinkCulture: ``,
      isoCode: `fr-FR`,
      mautic: {
        src: `https://mautic.easyproject.com/media/js/mautic-form.js`,
        domain: `https://mautic.easyproject.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-project.puxdesign.net`,
        stage: `http://dev1-ep.easysoftware.client.puxdesign.cz`,
        rdn: `http://rdn-1-ep.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyproject.com`,
      },
      GTM: `GTM-K75449W`,
    },
    es: {
      locale: `es`,
      urlPrefix: `es`,
      preferedContentLinkCulture: ``,
      isoCode: `es-ES`,
      mautic: {
        src: `https://mautic.easyproject.com/media/js/mautic-form.js`,
        domain: `https://mautic.easyproject.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-project.puxdesign.net`,
        stage: `http://dev1-ep.easysoftware.client.puxdesign.cz`,
        rdn: `http://rdn-1-ep.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyproject.com`,
      },
      GTM: `GTM-K75449W`,
    },
    it: {
      locale: `it`,
      urlPrefix: `it`,
      preferedContentLinkCulture: ``,
      isoCode: `it-IT`,
      mautic: {
        src: `https://mautic.easyproject.com/media/js/mautic-form.js`,
        domain: `https://mautic.easyproject.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-project.puxdesign.net`,
        stage: `http://dev1-ep.easysoftware.client.puxdesign.cz`,
        rdn: `http://rdn-1-ep.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyproject.com`,
      },
      GTM: `GTM-K75449W`,
    },
    hu: {
      locale: `hu`,
      urlPrefix: ``,
      preferedContentLinkCulture: ``,
      isoCode: `hu-HU`,
      mautic: {
        src: `https://mautic.easyproject.hu/media/js/mautic-form.js`,
        domain: `https://mautic.easyproject.hu`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-project.puxdesign.net`,
        stage: `http://dev3-ep.easysoftware.client.puxdesign.cz`,
        rdn: `http://rdn-3-ep.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyproject.hu`,
      },
      GTM: `GTM-NFPB4Z9`,
    },
    cs: {
      locale: `cs`,
      urlPrefix: ``,
      preferedContentLinkCulture: ``,
      isoCode: `cs-CZ`,
      mautic: {
        src: `https://mautic2017.easyproject.cz/media/js/mautic-form.js`,
        domain: `https://mautic2017.easyproject.cz`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-project.puxdesign.net`,
        stage: `http://dev2-ep.easysoftware.client.puxdesign.cz`,
        rdn: `http://rdn-2-ep.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyproject.cz`,
      },
      GTM: `GTM-5SQG2G5`,
    },
    en: {
      locale: `en`,
      urlPrefix: ``,
      preferedContentLinkCulture: ``,
      isoCode: `en-GB`,
      mautic: {
        src: `https://mautic.easyproject.com/media/js/mautic-form.js`,
        domain: `https://mautic.easyproject.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-project.puxdesign.net`,
        stage: `http://dev1-ep.easysoftware.client.puxdesign.cz`,
        rdn: `http://rdn-1-ep.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyproject.com`,
      },
      GTM: `GTM-K75449W`,
    },
  },
  EasySoftwareGatsby: {
    cs: {
      locale: `cs`,
      urlPrefix: `cs`,
      preferedContentLinkCulture: ``,
      isoCode: `cs-CZ`,
      mautic: {
        src: ``,
        domain: ``,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-software.puxdesign.net`,
        stage: `http://dev1-es.easysoftware.client.puxdesign.cz`,
        rdn: `http://rdn-es.easysoftware.client.puxdesign.cz`,
        production: `https://www.easysoftware.com`,
      },
      GTM: `GTM-NPCRBNG`,
    },
    en: {
      locale: `en`,
      urlPrefix: ``,
      preferedContentLinkCulture: ``,
      isoCode: `en-GB`,
      mautic: {
        src: ``,
        domain: ``,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-software.puxdesign.net`,
        stage: `http://dev1-es.easysoftware.client.puxdesign.cz`,
        rdn: `http://rdn-es.easysoftware.client.puxdesign.cz`,
        production: `https://www.easysoftware.com`,
      },
      GTM: `GTM-NPCRBNG`,
    },
  },
  EasyRedmineGatsbyAI: {
    cs: {
      locale: `cs`,
      urlPrefix: `cs`,
      preferedContentLinkCulture: `cs-CZ`,
      isoCode: `cs-CZ`,
      order: 1,
      language: `Čeština`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    ua: {
      locale: `ua`,
      urlPrefix: `ua`,
      preferedContentLinkCulture: `uk-UA`,
      isoCode: `uk-UA`,
      order: 9,
      language: `Українська`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    ro: {
      locale: `ro`,
      urlPrefix: `ro`,
      preferedContentLinkCulture: `ro-RO`,
      isoCode: `ro-RO`,
      order: 14,
      language: `Română`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    hu: {
      locale: `hu`,
      urlPrefix: `hu`,
      preferedContentLinkCulture: `hu-HU`,
      isoCode: `hu-HU`,
      order: 2,
      language: `Magyar`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    pl: {
      locale: `pl`,
      urlPrefix: `pl`,
      preferedContentLinkCulture: `pl-PL`,
      isoCode: `pl-PL`,
      order: 4,
      language: `Polski`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    it: {
      locale: `it`,
      urlPrefix: `it`,
      preferedContentLinkCulture: `it-IT`,
      isoCode: `it-IT`,
      order: 3,
      language: `Italiano`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    nl: {
      locale: `nl`,
      urlPrefix: `nl`,
      preferedContentLinkCulture: `nl-NL`,
      isoCode: `nl-NL`,
      order: 5,
      language: `Nederlands`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    bg: {
      locale: `bg`,
      urlPrefix: `bg`,
      preferedContentLinkCulture: `bg-BG`,
      isoCode: `bg-BG`,
      order: 15,
      language: `Български`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    tr: {
      locale: `tr`,
      urlPrefix: `tr`,
      preferedContentLinkCulture: `tr-TR`,
      isoCode: `tr-TR`,
      order: 6,
      language: `Türkçe`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    se: {
      locale: `se`,
      urlPrefix: `se`,
      preferedContentLinkCulture: `sv-SE`,
      isoCode: `sv-SE`,
      order: 8,
      language: `Svenska`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    dk: {
      locale: `dk`,
      urlPrefix: `dk`,
      preferedContentLinkCulture: `da-DK`,
      isoCode: `da-DK`,
      order: 16,
      language: `Dansk`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    fi: {
      locale: `fi`,
      urlPrefix: `fi`,
      preferedContentLinkCulture: `fi-FI`,
      isoCode: `fi-FI`,
      order: 17,
      language: `Suomi`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    no: {
      locale: `no`,
      urlPrefix: `no`,
      preferedContentLinkCulture: `nn-NO`,
      isoCode: `nn-NO`,
      order: 18,
      language: `Norsk`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    gr: {
      locale: `gr`,
      urlPrefix: `gr`,
      preferedContentLinkCulture: `el-GR`,
      isoCode: `el-GR`,
      order: 19,
      language: `Ελληνικά`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    il: {
      locale: `il`,
      urlPrefix: `il`,
      preferedContentLinkCulture: `he-IL`,
      isoCode: `he-IL`,
      order: 20,
      language: `עברית`,
      direction: `rtl`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    eu: {
      locale: `eu`,
      urlPrefix: `eu`,
      preferedContentLinkCulture: `eu-ES`,
      isoCode: `eu-ES`,
      order: 22,
      language: `Euskara`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    id: {
      locale: `id`,
      urlPrefix: `id`,
      preferedContentLinkCulture: `id-ID`,
      isoCode: `id-ID`,
      order: 10,
      language: `Bahasa Indonesia`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    vn: {
      locale: `vn`,
      urlPrefix: `vn`,
      preferedContentLinkCulture: `vi-VN`,
      isoCode: `vi-VN`,
      order: 11,
      language: `Tiếng Việt`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    cn: {
      locale: `cn`,
      urlPrefix: `cn`,
      preferedContentLinkCulture: `zh-CN`,
      isoCode: `zh-CN`,
      order: 12,
      language: `中文`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    ae: {
      locale: `ae`,
      urlPrefix: `ae`,
      preferedContentLinkCulture: `ar-001`,
      isoCode: `ar-001`,
      order: 7,
      language: `العربية`,
      direction: `rtl`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    ee: {
      locale: `ee`,
      urlPrefix: `ee`,
      preferedContentLinkCulture: `et-EE`,
      isoCode: `et-EE`,
      order: 21,
      language: `Eesti`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    th: {
      locale: `th`,
      urlPrefix: `th`,
      preferedContentLinkCulture: `th-TH`,
      isoCode: `th-TH`,
      order: 13,
      language: `ภาษาไทย`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
  },
  EasyProjectGatsbyAI: {},
  EasySoftwareGatsbyAI: {},
}

export const ptConfigs: PTConfigs = {
  EasySoftwarePage: {
    codeName: `easySoftwarePage`,
    componentName: `EasySoftwarePage`,
    zones: [`widgetZone`],
  },
  PuxHomePage: {
    codeName: `puxHomePage`,
    componentName: `PuxHomePage`,
    zones: [`widgetZone`],
  },
  EasySoftwareArticle: {
    codeName: `easySoftwareArticle`,
    componentName: `PreviwArticlePage`,
    zones: [`articleWidgets`],
  },
  EasySoftwareCaseStudy: {
    codeName: `easySoftwareCaseStudy`,
    componentName: `PreviewCaseStudyDetail`,
    zones: [`caseStudyWidgets`],
  },
  EasySoftwareFunction: {
    codeName: `easySoftwareFunction`,
    componentName: `PreviewFunctionDetail`,
    zones: [`widgetZone`],
  },
  EasySoftwareNews: {
    codeName: `easySoftwareNews`,
    componentName: `PreviewNewsDetail`,
    zones: [`widgetZone`],
  },
  EasySoftwareCategoryPage: {
    codeName: `easySoftwareCategoryPage`,
    componentName: `PreviewCategoryPage`,
    zones: [`categoryWidgets`],
  },
  PuxLandingPage: {
    codeName: `puxLandingPage`,
    componentName: `PreviewLandingPage`,
    zones: [`landingWidgets`],
  },
  EasySoftwareTrainingCenter: {
    codeName: `easySoftwareTrainingCenter`,
    componentName: `PreviewCategoryPage`,
    zones: [`topWidgetZone`, `bottomWidgetZone`],
  },
  EasySoftwarePricingPage: {
    codeName: `easySoftwarePricingPage`,
    componentName: `PreviewPricingPage`,
    zones: [`midWidgetZone`, `bottomWidgetZone`],
  },
  EasySoftwareKnowledgeBaseArticle: {
    codeName: `easySoftwareKnowledgeBaseArticle`,
    componentName: `PreviewKnowledgeBaseArticle`,
    zones: [],
  },
  EasySoftwareFaq: {
    codeName: `easySoftwareFaq`,
    componentName: `PreviewFaqDetail`,
    zones: [],
  },
}

export const searchConfig: SearchConfig = {
  EasyRedmineGatsby: {
    apiKey: `AIzaSyDe18pSt4eqcHdG93c1Hjfo08ZqbcqMF5Y`,
    apiUrl: `https://www.googleapis.com/customsearch/v1/siterestrict`,
    localizedOptions: {
      en: {
        configId: `08ee585c-549c-4a65-bd53-6ed5130babb2`,
        knowledgeBaseConfigId: `490c608e-dff6-47d8-82cb-50c38454aa29`,
      },
      ru: {
        configId: `f92f67ba-336d-4d3a-bba2-50bbf7261386`,
      },
      fr: {
        configId: `b7ab21fe-03c9-4720-995a-5251b6f9d6f7`,
      },
      kr: {
        configId: `319a1721-921d-4958-adb5-8b9182740ec2`,
      },
      br: {
        configId: `ba621fe1-9d98-4e58-8523-17781c5c451a`,
      },
      de: {
        configId: `064c7f96-aeba-43c6-a879-2a087699e02d`,
      },
      jp: {
        configId: `06b52079-93de-4e64-95c3-958c17bb9ca1`,
      },
      es: {
        configId: `65cecd2b-8f67-49f0-9766-49cf8ce2a9fc`,
      },
    },
  },
  EasyProjectGatsby: {
    apiKey: `AIzaSyDe18pSt4eqcHdG93c1Hjfo08ZqbcqMF5Y`,
    apiUrl: `https://www.googleapis.com/customsearch/v1/siterestrict`,
    localizedOptions: {
      en: {
        configId: `7cd2182a-9e90-4cfa-b1ba-28b5c6405ac8`,
        knowledgeBaseConfigId: `75156e0a-4561-4dc1-9fe4-1f92056f4502`,
      },
      cs: {
        configId: `81e212ac-ac8f-4c0a-82b4-e83d3c3460fa`,
        knowledgeBaseConfigId: `d679a8d5-ab58-4c2f-b298-a407537dcd40`,
      },
      hu: {
        configId: `7a05694d-510d-4761-9720-aaeb2e75f87a`,
        knowledgeBaseConfigId: `3208e1be-3679-491a-95ed-cb63e70aecf6`,
      },
      de: {
        configId: `475e32ed-659b-4c9f-83c4-e335c5626df1`,
      },
      es: {
        configId: `edb8355f-ffe1-4c2c-a612-b11eddbeee29`,
      },
      fr: {
        configId: `76317914-c4d9-444d-84e6-e9b97d7efc21`,
      },
      it: {
        configId: `2e83ac16-9273-41b8-ab7c-9c4f2e9651f2`,
      },
      ru: {
        configId: `9512e1d9-b8f7-4810-8641-2a57a97c4446`,
      },
    },
  },
  EasySoftwareGatsby: {
    apiKey: ``,
    apiUrl: ``,
    localizedOptions: {
      en: {
        configId: ``,
      },
      cs: {
        configId: ``,
      },
    },
  },
  EasyRedmineGatsbyAI: {
    apiKey: `AIzaSyDe18pSt4eqcHdG93c1Hjfo08ZqbcqMF5Y`,
    apiUrl: `https://www.googleapis.com/customsearch/v1/siterestrict`,
    localizedOptions: {
      /*
      cs: {
        index: `e36db913af5e64215`,
        knowledgeBaseUrl: `www.easyredmine.com/cs/dokumentace`,
      },
      ua: {
        index: `d3586e69fc0374009`,
        knowledgeBaseUrl: `www.easyredmine.com/documentation-of-easy-redmine`,
      },
      ro: {
        index: `24315f9494fc0476e`,
        knowledgeBaseUrl: `www.easyredmine.com/documentation-of-easy-redmine`,
      },
      hu: {
        index: `76bb49c7047574e12`,
        knowledgeBaseUrl: `www.easyredmine.com/documentation-of-easy-redmine`,
      },
      it: {
        index: `d5313c90e1b2242bd`,
        knowledgeBaseUrl: `www.easyredmine.com/documentation-of-easy-redmine`,
      },
      pl: {
        index: `b6a2aefe828e74d6c`,
        knowledgeBaseUrl: `www.easyredmine.com/documentation-of-easy-redmine`,
      },
      nl: {
        index: `156cac4a3d3db45c8`,
        knowledgeBaseUrl: `www.easyredmine.com/documentation-of-easy-redmine`,
      },
      bg: {
        index: `d7750af7e41c24207`,
        knowledgeBaseUrl: `www.easyredmine.com/documentation-of-easy-redmine`,
      },
      se: {
        index: `557976ccdc9044efa`,
        knowledgeBaseUrl: `www.easyredmine.com/documentation-of-easy-redmine`,
      },
      tr: {
        index: `31a18a1c8604e4805`,
        knowledgeBaseUrl: `www.easyredmine.com/documentation-of-easy-redmine`,
      },
      dk: {
        index: `a24fb481e18df4a97`,
        knowledgeBaseUrl: `www.easyredmine.com/documentation-of-easy-redmine`,
      },
      fi: {
        index: `a7aa0c1f6149242a6`,
        knowledgeBaseUrl: `www.easyredmine.com/documentation-of-easy-redmine`,
      },
      no: {
        index: `7308f32148d3545bc`,
        knowledgeBaseUrl: `www.easyredmine.com/documentation-of-easy-redmine`,
      },
      gr: {
        index: `108ee7adbf2e24db3`,
        knowledgeBaseUrl: `www.easyredmine.com/documentation-of-easy-redmine`,
      },
      il: {
        index: `c1f8af2af79674a57`,
        knowledgeBaseUrl: `www.easyredmine.com/documentation-of-easy-redmine`,
      },
      eu: {
        index: `b2d8f726139c64692`,
        knowledgeBaseUrl: `www.easyredmine.com/documentation-of-easy-redmine`,
      },
      id: {
        index: `22e8371926db94d8a`,
        knowledgeBaseUrl: `www.easyredmine.com/documentation-of-easy-redmine`,
      },
      vn: {
        index: `e76e36bbbab214d42`,
        knowledgeBaseUrl: `www.easyredmine.com/documentation-of-easy-redmine`,
      },
      cn: {
        index: ``,
        knowledgeBaseUrl: `www.easyredmine.com/documentation-of-easy-redmine`,
      },
      ae: {
        index: ``,
        knowledgeBaseUrl: `www.easyredmine.com/documentation-of-easy-redmine`,
      },*/
    },
  },
}

export interface configType {
  [site: string]: siteConfigType
}
export interface siteConfigType {
  [lang: string]: langConfig
}

export interface langConfig {
  locale: string
  urlPrefix: string
  isoCode: string
  order?: number
  language?: string
  preferedContentLinkCulture: string
  direction?: 'rtl' | 'ltr'
  mautic: mauticConfig
  domains: langDomain
  GTM: string
}

export interface langDomain {
  [domain: string]: string
}

export interface mauticConfig {
  src: string
  domain: string
}

interface SearchConfig {
  [project: string]: {
    apiKey: string
    apiUrl: string
    localizedOptions: SearchLocalizedOptions
  }
}

interface SearchLocalizedOptions {
  [locale: string]: {
    configId: string
    knowledgeBaseConfigId?: string
  }
}

interface PTConfig {
  codeName: string
  componentName: string
  zones: string[]
}

interface PTConfigs {
  [key: string]: PTConfig
}
