import { getFormQuery } from './form-queries'

export const getWidgetQuery = (
  key: string,
  preferedContentLinkCulture = ``
): string => {
  const queryWidgetProperties = `
    puxWidgetProperties {
      widgetPropertiesPartID
      widgetPropertiesPartMarginBottom
      widgetPropertiesPartMarginTop
      widgetPropertiesPartPaddingBottom
      widgetPropertiesPartPaddingTop
      widgetPropertiesPartMobileOrder
    }
    metadata {
      alignment
      size
    }
  `

  const queryWidgetAnimation = `
    puxWidgetAnimation {
      widgetAnimationPartType
      widgetAnimationPartIsEnabled
      widgetAnimationPartFraction
      widgetAnimationPartDuration
      widgetAnimationPartDelay
    }
  `

  const queryButtonProperties = `
    buttonColor
    buttonNewWindow
    buttonType
    buttonSize
    buttonOpenModalId
    buttonLink {
      url(culture: "${preferedContentLinkCulture}")
      internal
      text
    }
  `

  const queryImagePaths = `
    resizePaths(width: 1920, mode: "Min", quality: 80)
    resizePathsMobile(width: 768, mode: "Min", quality: 80)
    resizePathsTablet(width: 1200, mode: "Min", quality: 80)
    texts
  `

  const queryLottieAnimation = `
    ... on Orchard_EasySoftwareLottie {
      displayText
      lottieJSON             
      lottieJSONMobile
      lottieImage {
        ${queryImagePaths}
      }     
    }
  `

  const queryBackgroundVideo = `
    ... on Orchard_EasySoftwareBackgroundVideo {
      backgroundVideoFileMP4 {
        paths
        type
      }   
      backgroundVideoFileWEBM {
        paths
        type
      }    
      backgroundVideoPlaceholder {
        paths
      }     
      backgroundVideoLoop
      backgroundVideoAutoplay
      backgroundVideoControls
      backgroundVideoShowOnMobile
    }
  `

  const queryImageBoxListPicker = `
    cultureContentItems(culture: "${preferedContentLinkCulture}") {
      ... on Orchard_PuxImageBox {
        contentType
        contentItemId
        displayText
        imageBoxContent {
          html
        }
        imageBoxImage {
          mediaSelectorImage {
            ${queryImagePaths}
          }
          mediaSelectorLightbox
        }
        imageBoxButton {
          ${queryButtonProperties}
        }
      }
    }
  `

  const queryImageBoxListBag = `
    contentItems {
      ... on Orchard_PuxImageBox {
        contentType
        contentItemId
        displayText
        imageBoxContent {
          html
        }
        imageBoxImage {
          mediaSelectorImage {
            ${queryImagePaths}
          }
          mediaSelectorLightbox
        }
        imageBoxButton {
          ${queryButtonProperties}
        }
      }
    }
  `

  const queryMediaVideoProperties = `
    file {
      paths
    }
    muted
    autoplay
    loop
    controls
    width
    height
  `

  const queryPuxVideoProperties = `
    videoStructuredDataContentUrl
    videoStructuredDataDescription
    videoStructuredDataName
    videoStructuredDataUploadDate
    videoThumbnail {
      ${queryImagePaths}
    }
    videoYTID {
      html
    }
  `

  switch (key) {
    case `PuxWysiwyg`:
      return `
        ... on Orchard_PuxWysiwyg {
          contentType
          contentItemId
          wysiwygSmallContainer
          htmlBody {
            html
          }
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
        }
      `

    case `EasySoftwareBenefits`:
      return `
        ... on Orchard_EasySoftwareBenefits {
          contentType
          contentItemId
          displayText
          benefitsImagePosition
          benefitsImageSize
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
          benefitsList {
            ${queryImageBoxListPicker}
          }
        }
      `

    case `EasySoftwareCards`:
      return `
        ... on Orchard_EasySoftwareCards {
          contentType
          contentItemId
          displayText
          headingsColor
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
          cardsList {
            contentItems {
              ... on Orchard_EasySoftwareCardItem {
                contentItemId
                contentType
                displayText
                cardItemPerex {
                  html
                }
                cardButtonPrimary {
                  ${queryButtonProperties}
                }
                cardButtonSecondary {
                  ${queryButtonProperties}
                }
              }
            }
          }
        }
      `

    case `EasySoftwareCaseStudies`:
      return `
        ... on Orchard_EasySoftwareCaseStudies {
          contentType
          contentItemId
          displayText
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
          caseStudiesList {
            cultureContentItems(culture: "${preferedContentLinkCulture}") {
              ... on Orchard_EasySoftwareCaseStudy {
                contentType
                contentItemId
                displayText
                path
                caseStudyPerex {
                  html
                }
                caseStudyImage {
                  mediaSelectorImage {
                    ${queryImagePaths}
                  }
                }
                caseStudyParameters {
                  contentItems(first: 1) {
                    ... on Orchard_PuxKeyValue {
                      displayText
                      contentItemId
                      keyValueContent {
                        html
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `

    case `EasySoftwareFeatures`:
      return `
        ... on Orchard_EasySoftwareFeatures {
          contentType
          contentItemId
          displayText
          featuresPerex {
            html
          }
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
          featuresMainButton {
            ${queryButtonProperties}
          }
          featuresSecondaryButton {
            ${queryButtonProperties}
          }
          featuresList {
            ${queryImageBoxListBag}
          }
        }
      `

    case `EasySoftwareNavigator`:
      return `
        ... on Orchard_EasySoftwareNavigator {
          contentType
          contentItemId
          displayText
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
          navigatorCategories {
            contentItems {
              ... on Orchard_EasySoftwareNavigatorCategory {
                displayText
                navigatorCategorySubtitle
                contentItemId
                navigatorSubcategories {
                  contentItems {
                    ... on Orchard_EasySoftwareNavigatorSubcategory {
                      displayText
                      contentItemId
                      navigatorSubcategoryText {
                        html
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `

    case `EasySoftwareTable`:
      return `
        ... on Orchard_EasySoftwareTable {
          contentType
          contentItemId
          displayText
          tableContent {
            html
          }
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
        }
      `

    case `PuxGallery`:
      return `
        ... on Orchard_PuxGallery {
          displayText
          contentItemId
          contentType
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
          galleryItemsToLightbox
          galleryList {
            contentItems {
              contentItemId
              contentType
              ... on Orchard_PuxGalleryItem {
                contentType
                contentItemId
                galleryItemImage {
                  ${queryImagePaths}
                }
              }
            }
          }
        }
      `

    case `PuxGalleryWrapper`:
      return `
        ... on Orchard_PuxGalleryWrapper {
          displayText
          contentItemId
          contentType
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
          gallerySlider
          galleryWrapperItemsToLightbox
          galleryWrapperItemsSet {
            cultureContentItems(culture: "${preferedContentLinkCulture}") {
              ... on Orchard_PuxGallerySet {
                contentType
                contentItemId
                displayText
                puxGallerySetSize
                gallerySetItems {
                  mediaSelectorImage {
                    ${queryImagePaths}
                  }
                  mediaSelectorTitle {
                    html
                  }
                }
              }
          }
        }
      }
      `
    case `PuxLinkGalleryWrapper`:
      return `
        ... on Orchard_PuxLinkGalleryWrapper {
          displayText
          contentType
          contentItemId
          linkGalleryWrapperSlider
          linkGalleryWrapperItemsSet {
            cultureContentItems(culture: "${preferedContentLinkCulture}") {
              ... on Orchard_PuxLinkGallerySet {
                displayText
                contentType
                contentItemId
                linkGallerySetItems {
                  contentItems {
                    ... on Orchard_PuxLinkGalleryItem {
                      contentType
                      contentItemId
                      linkGalleryItemUrl {
                        url(culture: "${preferedContentLinkCulture}")
                        internal
                      }
                      puxMediaSelector {
                        mediaSelectorImage {
                          ${queryImagePaths}
                        }
                      }
                    }
                  }
                }
              }
            }
          }  
        }
        `

    case `PuxTopImage`:
      return `
        ... on Orchard_PuxTopImage {
          displayText
          contentItemId
          contentType
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
          topImageContent {
            html
          }
          topImageBenefit1 {
            html
          }
          topImageBenefit2 {
            html
          }
          topImageContentAlign
          topImageContentColor
          topImageButtonAlign
          topImageImageStyle
          topImageButton {
            ${queryButtonProperties}
          }
          topImageSecondaryButton {
            ${queryButtonProperties}
          }
          topImageBackground {
            mediaSelectorImage {
              ${queryImagePaths}
            }
          }
          puxVideo {
            ${queryPuxVideoProperties}
          }
        }
      `

    case `EasySoftwareSolutions`:
      return `
        ... on Orchard_EasySoftwareSolutions {
          contentType
          contentItemId
          displayText
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
          solutionsList {
            ${queryImageBoxListBag}
          }
          solutionsImageSize
          solutionsImagePosition
          solutionsRowItems
        }
      `

    case `EasySoftwareReviews`:
      return `
        ... on Orchard_EasySoftwareReviews {
          contentType
          contentItemId
          displayText
          reviewsText
          reviewsSlider
          reviewsImage {
            ${queryImagePaths}
          }
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
          reviewsList {
            cultureContentItems(culture: "${preferedContentLinkCulture}") {
              contentItemId
              contentType
              displayText
              ... on Orchard_EasySoftwareReview {
                displayText
                contentItemId
                contentType
                reviewContent {
                  html
                }
                reviewReviewerCompany {
                  html
                }
                reviewReviewerName {
                  html
                }
                reviewReviewerPosition {
                  html
                }
                reviewRatingList {
                  contentItems {
                    contentItemId
                    contentType
                    ... on Orchard_PuxRating {
                      contentItemId
                      contentType
                      displayText
                      ratingStars
                      ratingMaxStars
                    }
                  }
                }
              }
            }
          }
        }
      `
    case `PuxImageContent`:
      return `
        ... on Orchard_PuxImageContent {
          contentItemId
          contentType
          imageContentText {
            html
          }
          imageContentButtonAlign
          imageContentImagePosition
          imageContentTextAlign
          imageContentCollapseContent {
            html
          }
          imageContentGallery {
            mediaSelectorImage {
              ${queryImagePaths}
            }
          }
          puxMediaSelector {
            mediaSelectorImage {
              ${queryImagePaths}
            }
            mediaSelectorLightbox
          }
          puxButton {
            ${queryButtonProperties}
          }
          puxVideo {
            ${queryPuxVideoProperties}
          }
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
          puxImageContentMedia {
            cultureContentItems(culture: "${preferedContentLinkCulture}") {
              displayText
              contentType
              ... on Orchard_EasySoftwareHeroBannerBackground {
                displayText
                modifiedUtc
                heroBannerBackgroundImage {
                  mediaSelectorImage {
                    ${queryImagePaths}
                  }
                }
              }
              ${queryLottieAnimation}
              ${queryBackgroundVideo}
              ... on Orchard_PuxVideo {
                puxVideo {
                  ${queryPuxVideoProperties}
                }
              }
            }
          }
        }
      `

    case `PuxNumbersContainer`:
      return `
        ... on Orchard_PuxNumbersContainer {
          contentType
          contentItemId
          numbersContainerShowProgress
          numbersContainerLayout
          displayText
          puxNumbersContainerItems {
            contentItems {
              ... on Orchard_PuxNumber {
                numberDescription
                numberPrefix
                numberUnit
                numberValue
                contentItemId
              }
            }
          }
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
        }
      `

    case `PuxVideoContainer`:
      return `
        ... on Orchard_PuxVideoContainer {
          displayText
          contentType
          contentItemId
          videoContainerList {
            cultureContentItems(culture: "${preferedContentLinkCulture}") {
              ... on Orchard_PuxVideo {
                puxVideo {
                  videoStructuredDataContentUrl
                  videoStructuredDataDescription
                  videoStructuredDataName
                  videoStructuredDataUploadDate
                  videoYTID {
                    html
                  }
                  videoThumbnail {
                    ${queryImagePaths}
                  }
                }
              }
            }
          }
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
        }
        `

    case `PuxSimpleRepeater`:
      return `
        ... on Orchard_PuxSimpleRepeater {
          contentType
          contentItemId
          simpleRepeaterPageSize
          simpleRepeaterPagerOptions
          simpleRepeaterOnePageOnly
          simpleRepeaterFallbackCulture {
            html
          }
          puxSimpleFilter {
            simpleFilterTermIDs {
              termContentItemIds
            }
            simpleFilterExplicitIDs {
              cultureContentItems(culture: "${preferedContentLinkCulture}") {
                contentItemId
              }
            }
            simpleFilterContentType
            simpleFilterSortByIDs
            puxFilterIsHidden
          }
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
        }
      `

    case `EasySoftwareSideCardTrial`:
      return `
        ... on Orchard_EasySoftwareSideCardTrial {
          displayText
          contentType
          contentItemId
          sideCardTrialText {
            html
          }
          sideCardTrialButtonText
        }
      `

    case `EasySoftwareSideCardNewsletter`:
      return `
        ... on Orchard_EasySoftwareSideCardNewsletter {
          displayText
          contentType
          contentItemId
          sideCardNewsletterText {
            html
          }
        }
      `

    case `EasySoftwareSideCardCustom`:
      return `
        ... on Orchard_EasySoftwareSideCardCustom {
          displayText
          contentType
          contentItemId
          sideCardCustomText {
            html
          }
          sideCardCustomButton {
            ${queryButtonProperties}
          }
        }
      `

    case `EasySoftwareSideCardNavigation`:
      return `
        ... on Orchard_EasySoftwareSideCardNavigation {
          displayText
          contentType
          contentItemId
          sideCardNavigationLinks {
            contentItems {
              ... on Orchard_EasySoftwareSideCardNavigationLink {
                contentType
                contentItemId
                sideCardNavigationLinkIcon
                sideCardNavigationLinkNewWindow
                sideCardNavigationLinkItem {
                  internal
                  text
                  url
                }
              }
            }
          }
        }
      `

    case `EasySoftwareIconBar`:
      return `
        ... on Orchard_EasySoftwareIconBar {
          contentItemId
          contentType
          backgroundColor
          iconBarIconList {
            contentItems {
              ... on Orchard_EasySoftwareIconBarItem {
                contentItemId
                displayText
                iconBarItemImage {
                  ${queryImagePaths}
                }
              }
            }
          }
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
        }
      `

    case `EasySoftwareEmbeddedCodeContainer`:
      return `
        ... on Orchard_EasySoftwareEmbeddedCodeContainer {
          contentType
          contentItemId
          embeddedCodeContainerItems {
            cultureContentItems(culture: "${preferedContentLinkCulture}") {
              ... on Orchard_EasySoftwareEmbeddedCode {
                contentType
                contentItemId
                embeddedCodeShortCode {
                  html
                }
                embeddedCodeType
              }
              ... on Orchard_EasySoftwareDangerousEmbeddedCode {
                contentType
                contentItemId
                dangerousEmbeddedCodeSourceCode {
                  html
                }
                dangerousEmbeddedCodeType
              }
            }
          }
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
        }
      `

    case `EasySoftwareTimeline`:
      return `
        ... on Orchard_EasySoftwareTimeline {
          contentType
          contentItemId
          displayText
          timelineItems {
            contentItems {
              ... on Orchard_EasySoftwareTimelineItem {
                displayText
                contentItemId
                contentType
                timelineItemContent
                timelineItemURL
                timelineItemRichContent {
                  html
                }
              }
            }
          }
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
        }
      `

    case `EasySoftwareModules`:
      return `
        ... on Orchard_EasySoftwareModules {
          contentType
          contentItemId
          displayText
          modulesItem {
            cultureContentItems(culture: "${preferedContentLinkCulture}") {
              ... on Orchard_EasySoftwareModule {
                contentType
                contentItemId
                displayText
                moduleCore {
                  html
                }
                moduleIcon
                moduleLinks {
                  contentItems {
                    ... on Orchard_EasySoftwareSideCardNavigationLink {
                      contentType
                      contentItemId
                      sideCardNavigationLinkIcon
                      sideCardNavigationLinkItem {
                        internal
                        text
                        url(culture: "${preferedContentLinkCulture}")
                      }
                    }
                  }
                }
              }
            }
          }
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
        }
      `

    case `EasySoftwareTestimonials`:
      return `
        ... on Orchard_EasySoftwareTestimonials {
          contentType
          contentItemId
          displayText
          testimonialsList {
            cultureContentItems(culture: "${preferedContentLinkCulture}") {
              ... on Orchard_EasySoftwareTestimonial {
                contentType
                contentItemId
                testimonialName {
                  html
                }
                testimonialOccupation {
                  html
                }
                testimonialCompany {
                  html
                }
                testimonialText {
                  html
                }
                testimonialPhoto {
                  mediaSelectorImage {
                    ${queryImagePaths}
                  }
                }
              }
            }
          }
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
        }
      `

    case `EasySoftwareHeroBannerContainer`:
      return `
          ... on Orchard_EasySoftwareHeroBannerContainer {
            contentType
            contentItemId
            displayText
            heroBannerContainerTheme
            heroBannerContainerBackground {
              contentItems {
                ... on Orchard_EasySoftwareHeroBannerBackground {
                  displayText
                  modifiedUtc
                  heroBannerBackgroundImage {
                    mediaSelectorImage {
                      ${queryImagePaths}
                    }
                  }
                }
                ${queryLottieAnimation}
                ${queryBackgroundVideo}
              }
            }
            ${queryWidgetProperties} 
        
            heroBannerContainerItems {
              contentItems {
                contentType
                %HeroBannerItem%
            }
          }
        }
      `

    case `EasySoftwareHeroBannerItemPicture`:
      return `
        ... on Orchard_EasySoftwareHeroBannerItemPicture {
          contentItemId
          puxMediaSelector {
            mediaSelectorImage {
              ${queryImagePaths}
            }
          }
          heroBannerItemPictureContent {
            html
          }
          heroBannerItemPictureButton1 {
            ${queryButtonProperties}
          }
          heroBannerItemPictureButton2 {
            ${queryButtonProperties}
          }
          easySoftwareHeroBannerPosition {
            heroBannerPositionDesktop
            heroBannerPositionMobile
            heroBannerPositionMobileCenterContent
          }
        }
      `

    case `EasySoftwareHeroBannerItemAnimation`:
      return `
          ... on Orchard_EasySoftwareHeroBannerItemAnimation {
            contentItemId
            heroBannerItemAnimationContent {
              html
            }
            heroBannerItemAnimationButton1 {
              ${queryButtonProperties}
            }
            heroBannerItemAnimationButton2 {
              ${queryButtonProperties}
            }
            heroBannerItemAnimationLottie {
              contentItems {
                ${queryLottieAnimation}
              }
            }
            easySoftwareHeroBannerPosition {
              heroBannerPositionDesktop
              heroBannerPositionMobile
              heroBannerPositionMobileCenterContent
            }
            heroBannerItemAnimationMP4 {
              paths
              type
            }   
            heroBannerItemAnimationWEBM {
              paths
              type
            }    
            heroBannerItemAnimationPlaceholder {
              paths
            }     
            heroBannerItemAnimationLoop
            heroBannerItemAnimationAutoplay
            heroBannerItemAnimationControls
            heroBannerItemAnimationShowOnMobile      
          }
        `

    case `EasySoftwareHeroBannerItemVideo`:
      return `
        ... on Orchard_EasySoftwareHeroBannerItemVideo {
          contentItemId
          puxVideo {
            ${queryPuxVideoProperties}
          }
          heroBannerItemVideoContent {
            html
          }
          heroBannerItemVideoButton1 {
            ${queryButtonProperties}
          }
          heroBannerItemVideoButton2 {
            ${queryButtonProperties}
          }
          easySoftwareHeroBannerPosition {
            heroBannerPositionDesktop
            heroBannerPositionMobile
            heroBannerPositionMobileCenterContent
          }
        }
      `

    case `EasySoftwareHeroBannerItemText`:
      return `
        ... on Orchard_EasySoftwareHeroBannerItemText {
          contentItemId
          heroBannerItemTextContent {
            html
          }
          heroBannerItemTextButton1 {
            ${queryButtonProperties}
          }
          heroBannerItemTextButton2 {
            ${queryButtonProperties}
          }
          heroBannerItemTextBackgroundImage {
            mediaSelectorImage {
              ${queryImagePaths}
            }
          }
        }
      `

    case `EasySoftwareSecondaryNavigation`:
      return `
        ... on Orchard_EasySoftwareSecondaryNavigation {
          contentType
          contentItemId
          secondaryNavigationTitle
          bag {
            contentItems {
              ... on Orchard_EasySoftwareSideCardNavigationLink {
                sideCardNavigationLinkIcon
                sideCardNavigationLinkItem {
                  internal
                  text
                  url
                }
                sideCardNavigationLinkNewWindow
              }
            }
          }
          ${queryWidgetProperties}
          ${queryWidgetAnimation}
        }
      `

    case `EasySoftwareAccordion`:
      return `
          ... on Orchard_EasySoftwareAccordion {
            contentType
            contentItemId
            displayText
            accordionContentPosition
            accordionContentLocation
            accordionContentPositionDesktop
            accordionBackground
            ${queryWidgetProperties}
            ${queryWidgetAnimation}
            bag {
              contentItems {
                ... on Orchard_EasySoftwareAccordionItem {
                  contentItemId
                  displayText
                  accordionItemContent {
                    html
                  }
                  puxButton {
                    ${queryButtonProperties}
                  }
                  puxMediaSelector {
                    mediaSelectorImage {
                      ${queryImagePaths}
                    }
                    mediaSelectorLightbox
                  }
                  accordionItemMedia {
                    cultureContentItems(culture: "${preferedContentLinkCulture}") {
                      displayText
                      contentType
                      ... on Orchard_EasySoftwareHeroBannerBackground {
                        displayText
                        modifiedUtc
                        heroBannerBackgroundImage {
                          mediaSelectorImage {
                            ${queryImagePaths}
                          }
                        }
                      }
                      ${queryLottieAnimation}
                      ${queryBackgroundVideo}
                      ... on Orchard_PuxVideo {
                        puxVideo {
                          videoStructuredDataContentUrl
                          videoStructuredDataDescription
                          videoStructuredDataName
                          videoStructuredDataUploadDate
                          videoYTID {
                            html
                          }
                          videoThumbnail {
                            ${queryImagePaths}
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `

    case `EasySoftwareTabs`:
      return `
            ... on Orchard_EasySoftwareTabs {
              contentType
              contentItemId
              displayText
              tabsContentPositionDesktop
              tabsBackground
              ${queryWidgetProperties}
              ${queryWidgetAnimation}
              bag {
                contentItems {
                  ... on Orchard_EasySoftwareTabsItem {
                    contentItemId
                    displayText
                    tabsItemContent {
                      html
                    }
                    puxButton {
                      ${queryButtonProperties}
                    }
                    puxMediaSelector {
                      mediaSelectorImage {
                        ${queryImagePaths}
                      }
                      mediaSelectorLightbox
                    }
                    tabsItemMedia {
                      cultureContentItems(culture: "${preferedContentLinkCulture}") {
                        displayText
                        contentType
                        ... on Orchard_EasySoftwareHeroBannerBackground {
                          displayText
                          modifiedUtc
                          heroBannerBackgroundImage {
                            mediaSelectorImage {
                              ${queryImagePaths}
                            }
                          }
                        }
                        ${queryLottieAnimation}
                        ${queryBackgroundVideo}
                        ... on Orchard_PuxVideo {
                          puxVideo {
                            videoStructuredDataContentUrl
                            videoStructuredDataDescription
                            videoStructuredDataName
                            videoStructuredDataUploadDate
                            videoYTID {
                              html
                            }
                            videoThumbnail {
                              ${queryImagePaths}
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          `

    case `PuxForm`:
      return getFormQuery()

    case `PuxFormContainer`:
      return `
        ... on Orchard_PuxFormContainer {
          contentType
          displayText
          formContainerSelector {
            contentItemIds
          }
        }
      `

    default:
      return ``
  }
}
