import { ErrorMessage } from '@hookform/error-message'
import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { dt } from 'Root/Shared/utils/dynamicRS'
import { t } from 'ttag'

import { FormFieldInputType } from '../builders/FormBuilder'
import { WidgetProperitesPart } from '../builders/WidgetBuilder'
import FormErrorHelper from './FormErrorHelper'
import { getClass, getRequiredField } from './formFieldHelper'

const FormFieldCheckboxWidget: React.FC<{
  props: FormFieldInputType
  widgetProperties?: WidgetProperitesPart
}> = ({ props, widgetProperties }) => {
  const formElement = props?.puxFormElement
  const formValidation = props?.puxFormElementValidation
  const formOptions = props?.puxFormElementOptions

  const [checked, setChecked] = useState<boolean>(
    props?.puxFormElement.formElementDefaultValue === `checked`
  )

  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <div
      className={`FormGroup ${
        formOptions?.formElementOptionsCssClass ?? ``
      } ${getClass(props?.metadata?.size, widgetProperties)}`}
    >
      <div className='FormGroup-centered'>
        <Controller
          name={formElement?.formElementName}
          control={control}
          rules={{
            required: formValidation.formElementValidationRequired
              ? dt(`PuxForm.Error.Required`)
              : false,
          }}
          render={({ field }) => (
            <>
              <input
                {...field}
                name={formElement?.formElementName}
                id={formElement?.formElementName}
                type={props?.inputType}
                placeholder={formElement?.formElementPlaceholder}
                disabled={formOptions?.formElementOptionsDisabled}
                className={formOptions?.formElementOptionsCssClass ?? ``}
                checked={checked}
                onClick={() => {
                  setChecked(!checked)
                }}
              />
              <label htmlFor={formElement?.formElementName}>
                <div className='fake-check'>
                  {dt(`${formElement?.formElementLabel}`)}
                  {getRequiredField(
                    props?.puxFormElementValidation
                      ?.formElementValidationRequired
                  )}
                </div>
              </label>
            </>
          )}
        />
        <ErrorMessage
          render={(data) => <FormErrorHelper {...data} />}
          errors={errors}
          name={formElement?.formElementID ?? ``}
        />
      </div>
    </div>
  )
}

export default FormFieldCheckboxWidget
