import 'react-toastify/dist/ReactToastify.css';
import './EasySoftwareEditorContainer.scss'

import React, { FunctionComponent, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { useEditorStatus } from 'Root/Shared/utils/edit/useEditorStatus';

import EasySoftwareWysiwygEditor from '../EasySoftwareWysiwygEditor/EasySoftwareWysiwygEditor'
import PuxWysiwyg, { PuxWysiwygHtmlBodyType } from '../PuxWysiwyg/PuxWysiwyg'
import EasySoftwareInputEditor from '../EasySoftwareInputEditor/EasySoftwareInputEditor';

declare global {
  interface Window {
    pux: any
  }
}

export interface EasySoftwareEditorContainerType {
  content: PuxWysiwygHtmlBodyType | string;
  wysiwygId?: string;
  className?: string;
  inline?: boolean;
  small?: boolean;
  pageContentItemId: string;
  editorPath: string; // Used in news, to set unique id for perex and content wysiwygs
  saveText?: boolean;
  type?: "wysiwyg" | "input";
}

const EasySoftwareEditorContainer: FunctionComponent<EasySoftwareEditorContainerType> = (props) => {
  const isEditor = useEditorStatus();
  const [editorInitialized, setEditorInitialized] = useState(false);
  const [wysiwygValue, setWysiwygValue] = useState(props.content);

  if (!props.wysiwygId) {
    props.wysiwygId = props.pageContentItemId
  }

  async function saveEditor(value, EditorRef, EditorButtonRef) {
    if (!props.pageContentItemId || !props.editorPath) {
      console.log(`DEBUG pageContentItemId: ${props.pageContentItemId}`)
      console.log(`DEBUG wysiwygId: ${props.wysiwygId}`)
      console.log(`DEBUG editorPath: ${props.editorPath}`)
      toast.error(`Editor is missing parameters.`)
      return
    }

    await fetch((`${process.env.GATSBY_ORCHARD_API_URL}/puxapi/contentitem/editfield`), {
      method: `POST`,
      headers: {
        'Content-Type': `application/json`,
      },
      credentials: `include`,
      body: JSON.stringify(
        {
          contentitemid: props.pageContentItemId,
          path: props.editorPath,
          value: props.saveText ? value.replace(/<(\/*)p[^>]*>/g, ``) : value
        }
      )
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success(`Editor saved.`)
          EditorRef.current?.classList.remove(`WysiwygEditor--error`)
          EditorButtonRef.current?.classList.remove(`WysiwygEditor-button--changed`)
          window.pux = window.pux || {}
          window.pux.activeWysiwyg = false
          setWysiwygValue(props.type === "input" ? value : { html: props.saveText ? value.replace(/<(\/*)p[^>]*>/g, ``) : value })
          setEditorInitialized(false)
        }
        if (response.status === 401) {
          toast.error(`You are not authorized to edit this culture`)
          EditorRef.current?.classList.add(`WysiwygEditor--error`)
        }
        if (response.status === 500 || response.status === 400) {
          toast.error(`Editor error 500.`)
          EditorRef.current?.classList.add(`WysiwygEditor--error`)
        }
      })
      .catch((error) => {
        console.log(`Error saving editor data:`, error)
        toast.error(`Editor error.`)
        EditorRef.current?.classList.add(`WysiwygEditor--error`)
      })
  }

  const closeEditor = () => {
    setEditorInitialized(false)
    window.pux = window.pux || {}
    window.pux.activeWysiwyg = false
  }

  if (isEditor && editorInitialized) {
    return (
      <>
        {props.type === "input" ? (
          <EasySoftwareInputEditor
            path={props.editorPath}
            pageContentItemId={props.pageContentItemId}
            wysiwygId={props.wysiwygId}
            className={props.className}
            inline={props.inline}
            small={props.small}
            closeEditor={closeEditor}
            saveEditor={saveEditor}
          />) : (
          <EasySoftwareWysiwygEditor
            path={props.editorPath}
            pageContentItemId={props.pageContentItemId}
            wysiwygId={props.wysiwygId}
            className={props.className}
            inline={props.inline}
            small={props.small}
            closeEditor={closeEditor}
            saveEditor={saveEditor}
          />
        )}
      </>
    )
  } else if (isEditor) {
    return (
      <div
        className="Editor-container"
        onClick={() => {
          if (window?.pux?.activeWysiwyg) {
            toast.warning(`Close active editor, to open new instance.`);
            return;
          }

          if (typeof wysiwygValue === 'object' && wysiwygValue.html.includes("<table")) {
            toast.warning(`Wysiwyg contains table, open CMS to edit.`);
            return;
          }

          window.pux = window.pux || {};
          window.pux.activeWysiwyg = true;
          setEditorInitialized(true);
        }}
      >
        {props.type === "input" ? <div className='Input'>{typeof wysiwygValue === "string" ? wysiwygValue : wysiwygValue?.toString() ?? ""}</div> :
          <PuxWysiwyg
            content={wysiwygValue}
            wysiwygId={props.wysiwygId}
            className={props.className}
            inline={props.inline}
            small={props.small}
          />
        }
      </div>
    )
  } else {
    return props.type === "input" ? <div className='Input'>{typeof wysiwygValue === "string" ? wysiwygValue : wysiwygValue?.toString() ?? ""}</div> :
      <PuxWysiwyg
        content={wysiwygValue}
        wysiwygId={props.wysiwygId}
        className={props.className}
        inline={props.inline}
        small={props.small}
      />
  }
}

export default EasySoftwareEditorContainer
