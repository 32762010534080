import { langConfig, localeConfig } from "Root/puxconfig"
import { getLocalizedData } from "../localeURL"

export function getLocaleFromUrl(url: string, prefixType: "edit" | "preview", overrideLocale?: string): string {
    const EDITOR_URL_PREFIX = prefixType === "edit" ? "/edit" : "/preview"
    const urlWithoutPrefix = url.replace(EDITOR_URL_PREFIX, "").replace("//", "/")

    const locale = getLocaleFromLocalizedUrl(urlWithoutPrefix, overrideLocale)

    return locale
}

export function getPrefixedUrl(localizedUrl: string, prefixType: "edit" | "preview", overrideLocale?: string): string {
    const EDITOR_URL_PREFIX = prefixType === "edit" ? "/edit" : "/preview"
    const urlWithoutPrefix = localizedUrl.replace(EDITOR_URL_PREFIX, "")

    const CMSUrl = getCMSUrlFromLocalizedUrl(urlWithoutPrefix, overrideLocale)

    return CMSUrl
}

function getCMSUrlFromLocalizedUrl(localizedUrl: string, overrideLocale?: string): string {
    const CULTURE_URL_REGEX = /^\/?(?<culture>[^\/]*)\/?/;
    const allLocalesArray = getAllLocalesArray()
    const prefixArray: string[] = []
    let CMSUrl: string = ""

    allLocalesArray
        .map(locale => prefixArray.push(locale.urlPrefix))

    prefixArray.forEach(prefix => {
        const isoCode = getLocaleByUrlPrefix(prefix, overrideLocale).isoCode
        let overridedIsoCode = overrideLocale ? getLocalizedData(overrideLocale, process.env.PROJECT!).isoCode : undefined

        // handle localized homepage url for en
        // result: /en-GB/

        if (localizedUrl === prefix + "/" || localizedUrl === prefix) {
            if (overridedIsoCode) {
                CMSUrl = `${overridedIsoCode}/`
            } else {
                CMSUrl = `${isoCode}/`
            }
        }

        // handle localized url - "en/some-url"
        // result: /en-GB/some-url/
        const regexCulture = localizedUrl.match(CULTURE_URL_REGEX)
        if (regexCulture?.groups && regexCulture.groups["culture"]) {
            if (regexCulture.groups["culture"] === prefix) {
                // localized url for all prefixes but en
                CMSUrl = `${localizedUrl.replace(CULTURE_URL_REGEX, isoCode + "/")}`
            } else if (isoCode === overridedIsoCode && (!prefixArray.includes(regexCulture.groups["culture"]))) {
                // localized url for en
                if (overridedIsoCode) {
                    CMSUrl = `${overridedIsoCode}${localizedUrl}`.replace(/\/$/, "")
                } else {
                    CMSUrl = `${isoCode}${localizedUrl}`.replace(/\/$/, "")
                }
            }
        }
    })

    return CMSUrl
}

function getLocaleFromLocalizedUrl(localizedUrl: string, overrideIsoCode?: string): string {
    const CULTURE_URL_REGEX = /^\/?(?<culture>[^\/]*)\/?/;
    const allLocalesArray = getAllLocalesArray()
    const prefixArray: string[] = []
    let localeFromUrl: string = ""

    allLocalesArray
        .map(locale => prefixArray.push(locale.urlPrefix))

    prefixArray.forEach(prefix => {
        const isoCode = getLocaleByUrlPrefix(prefix, overrideIsoCode).isoCode
        let overridedLocale = overrideIsoCode ? getLocalizedData(overrideIsoCode, process.env.PROJECT!).locale : undefined

        // handle localized homepage url for en
        // result: /en-GB/
        if (localizedUrl === prefix + "/" || localizedUrl === prefix) {
            if (overridedLocale) {
                localeFromUrl = overridedLocale
            } else {
                localeFromUrl = `en`
            }
        }

        // handle localized url - "en/some-url"
        // result: /en-GB/some-url/
        const regexCulture = localizedUrl.match(CULTURE_URL_REGEX)

        if (regexCulture?.groups && regexCulture.groups["culture"]) {
            if (regexCulture.groups["culture"] === prefix) {
                localeFromUrl = prefix
            } else if (isoCode === overrideIsoCode && (!prefixArray.includes(regexCulture.groups["culture"]))) {
                // localized url for en
                if (overridedLocale) {
                    localeFromUrl = overridedLocale
                } else {
                    localeFromUrl = `en`
                }
            }
        }
    })

    return localeFromUrl
}

function getLocaleByUrlPrefix(urlPrefix: string, overrideLocale?: string): langConfig {
    if (urlPrefix === "" && overrideLocale) {
        return getLocalizedData(overrideLocale, process.env.PROJECT!)
    }

    const localeArray = getAllLocalesArray()
    const locale = localeArray.find(locale => locale.urlPrefix === urlPrefix)

    if (!locale) {
        throw `Error when getting Locale by URLPrefix in editURL.ts. Wanted URLPrefix: "${urlPrefix}"`
    }

    return locale
}

function getAllLocalesArray(): langConfig[] {
    const SERVER_PROJECT = process.env.PROJECT ?? "EasyRedmineGatsby"
    const LOCALE_CONFIG = localeConfig
    // localeConfig[SERVER_PROJECT]
    const localeArray: langConfig[] = []

    Object.keys(LOCALE_CONFIG)
        .filter(project => project.includes(SERVER_PROJECT))
        .forEach(locale => {
            localeArray.push(...Object.values(LOCALE_CONFIG[locale]))
        })

    Object.keys(LOCALE_CONFIG)
        .filter(project => project.includes(SERVER_PROJECT + "AI"))
        .forEach(locale => {
            localeArray.push(...Object.values(LOCALE_CONFIG[locale]))
        })

    return localeArray
}

export function isEditByUrl(url: string): boolean {
    const urlArray = url.startsWith('/') ?
        url.replace('/', '').split('/') :
        url.split('/')

    if (!urlArray) {
        return false
    }

    if (urlArray[0] !== "edit") {
        return false
    }

    return true
}

export function isPreviewByUrl(url: string): boolean {
    const urlArray = url.startsWith('/') ?
        url.replace('/', '').split('/') :
        url.split('/')

    if (!urlArray) {
        return false
    }

    if (urlArray[0] !== "preview") {
        return false
    }

    return true
}